<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="19">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{ this.direcParam.directName }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
      <el-col :span="5">
        <div>
          <el-input placeholder="请输入搜索内容" v-model.trim="tableParam.key" style="width:100%" @change="tableChangeSearchKey">
            <el-button type="primary" slot="append"><i class="fa fa-search" style="color:#409EFF"></i></el-button>
          </el-input>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-button-group>
          <el-button @click="formDataNew" type="primary">新建</el-button>
          <el-button @click="formDataModify" type="primary">修改</el-button>
          <el-button @click="formDataDelete" type="primary">删除</el-button>
        </el-button-group>
      </el-col>
    </el-row>

    <el-table ref="bizPricesTableRef" :key="tableParam.tableKey" :border="true" height="666" :cell-style="{height:'44px',padding:0,margin:0}" :data="tableData"
      tooltip-effect="dark" style="width: 100%" :highlight-current-row="true" @selection-change="tableSelectionChange" @row-click="tableRowClick">
      <el-table-column header-align="center" type="selection" width="40"></el-table-column>
      <el-table-column header-align="center" label="ID" prop="id" width="80"></el-table-column>
      <el-table-column header-align="center" label="标准名称" prop="pricesName">
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.pricesName)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="收费标准" prop="elePrice" width="300">
        <template slot-scope="scope">
          电价:{{scope.row.elePrice|yuanFilter}}元/度 服务费:{{scope.row.servicePrice|yuanFilter}}元/度
        </template>

      </el-table-column>
      <el-table-column header-align="center" label="用户档位显示方式" prop="showKind" width="600">
        <template slot-scope="scope">
          <span v-if="scope.row.showKind==0">按金额:
            <el-tag slot="reference" v-for="(item,index) in scope.row.showSetting" size="small" style="margin-left: 3px;"
              :key="'moneyType_'+scope.row.id+'_'+index">
              <i class="fa fa-bolt" style="color:#409EFF" :key="'showSetting'+item.id">
                {{ item.value|yuanFilter }}{{ scope.row.showKindTitle }}</i>
            </el-tag>
          </span>
          <span v-else-if="scope.row.showKind==1">按电量:
            <el-tag slot="reference" v-for="(item,index) in scope.row.showSetting" size="small" style="margin-left: 3px;"
              :key="'powerType_'+scope.row.id+'_'+index">
              <i class="fa fa-bolt" style="color:#409EFF" :key="'showSetting'+item.id"> {{ item.value}}{{ scope.row.showKindTitle }}</i>
            </el-tag>
          </span>
          <span v-if="scope.row.enoughCan">

            <el-tooltip placement="top">
              <div slot="content" style="line-height:20px;">
                收款方式:
                <span v-if="formData.enoughType==0">预收费,结算后,余额按[退款方式]退回</span>
                <span v-else-if="formData.enoughType==1">固定金额充满</span><br />
                收费金额:{{scope.row.enoughFunds|yuanFilter}} 元<br />
                时长限制:{{scope.row.enoughMaxTime}} 分钟 约{{(scope.row.enoughMaxTime/60).toFixed(2)}}小时 <br />
              </div>
              <el-tag size="small" type="success" style="margin-left: 3px;">
                <i class="fa fa-rocket">充满自停</i>
              </el-tag>
            </el-tooltip>
          </span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="退款方式" prop="refundPath">
        <template slot-scope="scope">
          <span v-if="scope.row.refundPath==0">
            原路退回
          </span>
          <span v-else>
            退回钱包(暂不支持)
          </span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="tableSizeChange" @current-change="tableCurrentChange" :current-page="tableParam.page"
      :page-sizes="[30,40, 50, 60,70,80,90,100]" :page-size="tableParam.limit" layout="total, sizes, prev, pager, next, jumper" :total="tableParam.count">
    </el-pagination>

    <!--编辑的表单-->

    <el-dialog v-dialogDrag :title="formDialogParam.title" :visible.sync="formDialogParam.visible" :width="formDialogParam.width" :top="formDialogParam.top"
      :close-on-click-modal='false'>
      <el-form ref="bizPricesFormRef" :model="formData" :rules="formDialogRules" label-width="80px" size="small"><!-- `prop` 为绑定的规则 -->
        <el-form-item label="标准名称" prop="pricesName">
          <el-input id="pricesName" v-model="formData.pricesName" placeholder="请输入标准名称" maxlength="100" show-word-limit></el-input>
        </el-form-item>
        <el-row style="padding:0px;" :gutter="20">
          <el-col :span="12">
            <el-form-item label="电价标准" prop="elePrice">
              <el-input id="elePrice" v-model="formData.elePrice" type="number" :min="10" :max="1000" placeholder="请输入电价">
                <template slot="append">{{formData.elePrice|yuanFilter}}元/度</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="服务费" prop="servicePrice">
              <el-input id="servicePrice" v-model="formData.servicePrice" type="number" :min="10" :max="1000" placeholder="请输入服务费">
                <template slot="append">{{formData.servicePrice|yuanFilter}}元/度</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <H4>车主显示档位</H4>
        <el-form-item label="显示方式" prop="showKind" size="large">
          <el-radio-group v-model="formData.showKind" @change="changeShowKind">
            <el-radio :label="0" border>金额</el-radio>
            <el-radio :label="1" border>电量</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-row style="padding:0px;" :gutter="20">
          <el-col :span="8" v-for="(item,index) in this.formData.showSetting" :key="'showSetting'+item.id">
            <el-form-item :label="'价格'+(item.id)" prop="showSetting">
              <el-input v-model="item.value" type="number" :min="1" :max="5000">
                <template slot="append" v-if="formData.showKind==0">{{item.value|yuanFilter}}{{formData.showKindTitle}}</template>
                <template slot="append" v-else>{{formData.showKindTitle}}</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="退款方式" prop="refundPath">
          <el-radio-group v-model="formData.refundPath">
            <el-radio :label="0">结算剩余,原路退回</el-radio>
            <el-radio :label="1">结算剩余,退回用户余额钱包(暂不支持)</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-card>
          <el-form-item label="充满自停">
            <el-checkbox v-model="formData.enoughCan">开启</el-checkbox>
            <span>&nbsp;&nbsp;&nbsp; *开启后,用户界面显示-充满自停，支付设置的[收费金额]，根据[时长限制]给车主充电</span>
          </el-form-item>
          <el-form-item label="收款方式" prop="enoughType" v-if="formData.enoughCan">
            <el-radio-group v-model="formData.enoughType">
              <el-radio :label="0">预收费,结算后,余额按[退款方式]退回</el-radio>
              <el-radio :label="1">固定金额充满</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-row style="padding:0px;" :gutter="20">
            <el-col :span="12">
              <el-form-item label="收费金额" prop="enoughFunds" v-if="formData.enoughCan" size="mini">
                <el-input id="enoughFunds" v-model="formData.enoughFunds" type="number" :min="100" :max="5000" placeholder="请输入收费金额">
                  <template slot="append">{{formData.enoughFunds|yuanFilter}}元</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="时长限制" prop="enoughMaxTime" v-if="formData.enoughCan" size="mini">
                <el-input id="enoughMaxTime" v-model="formData.enoughMaxTime" type="number" :min="1" :max="48" placeholder="请输入最长充电时间">
                  <template slot="append">分钟</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
      </el-form>
      <span slot="footer">
        <el-button @click="formDialogParam.visible = false">取 消</el-button>
        <el-button type="primary" @click="formDataSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog v-dialogDrag :title="formChooseDialogParam.title" :visible.sync="formChooseDialogParam.visible" width="50%" :close-on-click-modal='false'>
      <el-table :data="tableMultipleSelection" style="width: 100%;" height="300">
        <el-table-column header-align="center" type="index" width="40"></el-table-column>
        <el-table-column prop="title" label="选定的记录">
        </el-table-column>
      </el-table>
      <span slot="footer">
        <el-button @click="formChooseDialogParam.visible = false">取 消</el-button>
        <el-button v-if="formChooseDialogParam.action1" type="danger" @click="formChooseSubmit(false)">{{formChooseDialogParam.action1View}}</el-button>
        <el-button v-if="formChooseDialogParam.action2" type="primary" @click="formChooseSubmit(true)">{{formChooseDialogParam.action2View}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<style lang="less" scoped>
@import "@/assets/css/TableCus.css";
</style>
<script>
import store from '@/store';
import { getTableData, doNew, doModify, doDelete, } from '@/api/biz/BizPrices'; //数据源,如果没有上传文件的部分,请删除postFile, postImg,不删除也不影响
import { required, phone, len, email } from "@/utils/formRules" //校验规则,如有特殊要求请自己去修改填写,请删除不使用的,但不删除也不影响
export default {
  data () {
    return {
      /*导航栏状态 */
      direcParam: {
        'directName': '名称名称名称名称名称名称名称名称名称BizPrices'
      },
      /*表格参数 */
      tableParam: {
        'page': 1,
        'limit': 40,
        'count': 0,
        'key': '',
        'tableKey': 0,
        'searchType': 'normal'
      },//表格的传参
      /*表格数据 */
      tableData: [],
      /*表格选中的数据 */
      tableMultipleSelection: [], //多选的数据
      /*表单的参数 */
      formDialogParam: //form弹出窗口的显示状态
      {
        visible: false,
        title: '编辑',
        formDataAction: '',
        width: '50%',
        top: '5vh',
      },
      formChooseDialogParam: //form发布弹出窗口的显示状态
      {
        formChooseAction: '',
        title: '',
        visible: false,
        action1: false,
        action1View: '',
        action2: false,
        action2View: ''
      },
      /*原始数据 */
      formRawData: {}, //由于不能直接清空所有的数据回到初使状态(表格参数据库和搜索数据不能清空),所以建立,在mount的时候作一个深拷贝,将formData的数据初使化的原始数据复制一份过来
      /*初使化数据 */
      formData: {
        pricesName: '',
        elePrice: 0,
        servicePrice: 0,
        showKind: 0,
        showKindTitle: '元',
        showSetting: [
          { id: '1', value: 1 },
          { id: '2', value: 2 },
          { id: '3', value: 3 },
          { id: '4', value: 4 },
          { id: '5', value: 5 },
          { id: '6', value: 10 },
        ],
        refundPath: 0,
        enoughCan: false,
        enoughType: 0,
        enoughFunds: 0,
        enoughMaxTime: 12,

      },
      formDialogRules:
      {
        pricesName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        elePrice: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        servicePrice: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        showKind: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        showKindTitle: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        showSetting: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        refundPath: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        enoughCan: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        enoughType: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        enoughFunds: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        enoughMaxTime: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增

        // title: [required,len(0,255)],多规则绑定
        // mail: [required,len(0,255), email],多规则绑定
        // authorWriter: [required],
      },
    }
  },
  methods: {

    changeShowKind (value) {
      if (value == 0) {
        this.formData.showKindTitle = "元"
      } else if (value == 1) {
        this.formData.showKindTitle = "度"
      }

    },
    /*-------------------------------------------------表单(form)事件 -----------------------------------------------*/
    /*表单(form)-新建 */
    formDataNew () {
      this.formData = JSON.parse(JSON.stringify(this.formRawData)); //深拷贝一份原始数据到formData,就相当于重置表单了
      this.formDialogParam.title = '新建';
      this.formDialogParam.formDataAction = 'new';
      this.formDialogParam.visible = true;
    },
    /*表单(form)-修改 */
    formDataModify () {
      if (this.tableMultipleSelection.length != 1) {
        this.notifyInfo("您只能选择一条记录,才能修改");
      }
      else {
        this.formData = JSON.parse(JSON.stringify(this.tableMultipleSelection[0])); //深COPY主要是为了不想改了,就渲染到table上造成不必要的麻烦;
        this.formDialogParam.formDataAction = 'modify';
        this.formDialogParam.title = '修改';
        this.formDialogParam.visible = true;
      }

    },
    /*表单(form)-删除 */
    formDataDelete () {
      if (this.tableMultipleSelection.length < 1) {
        this.notifyInfo("您必须最少选择一条记录,才能删除");
        return;
      }
      else {
        this.formChooseDialogParam.title = "是否真的要删除选定的记录(已发布的不删除)"
        this.formChooseDialogParam.formChooseAction = 'delete';
        this.formChooseDialogParam.action1 = true;
        this.formChooseDialogParam.action1View = '删 除';
        this.formChooseDialogParam.action2 = false;
        this.formChooseDialogParam.action2View = '';
        this.formChooseDialogParam.visible = true;
      }
    },
    formDataValidate () {
      let result = true;
      this.$refs.bizPricesFormRef.validate((valid) => {
        result = valid;
      });
      return result;
    },
    /*表单(form)-提交 */
    formDataSubmit () {
      if (!this.formDataValidate()) return;
      this.formDialogParam.visible = false;
      //如有特殊的Json要求,请深Copy一份,再进行修改例如
      const postData = JSON.parse(JSON.stringify(this.formData));
      postData.showSetting = JSON.stringify(this.formData.showSetting);//由于MysqlJson的原因

      //postData.classId = this.tableParam.classId;
      //postData.imageUrl = JSON.stringify(this.formData.imageUrl);//由于MysqlJson的原因
      //postData.addFile = JSON.stringify(this.formData.addFile);//由于MysqlJson的原因
      //doNew(this.formData).then(response => {
      if (this.formDialogParam.formDataAction == 'new') {
        doNew(postData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })
      }
      else if (this.formDialogParam.formDataAction == 'modify') {
        doModify(postData).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
    },
    formChooseSubmit () {
      this.formChooseDialogParam.visible = false;
      if (this.formChooseDialogParam.formChooseAction == 'delete') {
        const selectNum = this.tableMultipleSelection.length;
        let canId = [];
        for (let i = 0; i < selectNum; i++) {
          if (!this.tableMultipleSelection[i].isPublic) {
            canId.push(this.tableMultipleSelection[i].id);
          }
        }
        const data = new FormData()
        data.append('id', canId);
        doDelete(data).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
    },
    /*-------------------------------------------------表格事件 -----------------------------------------------*/
    //表格-多选事件,当checkBox被选中,改变已选的值
    tableSelectionChange (val) {
      this.tableMultipleSelection = val;
    }
    ,
    /*表格-行选,点击事件 */
    tableRowClick (row) {
      //this.formData = row;
      this.formData = JSON.parse(JSON.stringify(row));//主要是为了不想改了就生效到table上
      this.$refs.bizPricesTableRef.clearSelection(); //清除选中
      this.$refs.bizPricesTableRef.toggleRowSelection(row); //设定选中
    },
    /*表格-改变每页显示多少条,直接重置到第一页*/
    tableSizeChange (val) {
      if (val != this.tableParam.limit) {
        this.tableParam.limit = val
        this.tableParam.page = 1;
        this.tableDateRefresh();
      }
    },
    /*表格-改变第几页*/
    tableCurrentChange (val) {
      if (val != this.tableParam.page) {
        this.tableParam.page = val;
        this.tableDateRefresh();
      }

    },
    /*表格-搜索关键词,由于改变才会重搜,所以不用在意 */
    tableChangeSearchKey (searchKey) {
      this.tableParam.key = searchKey;
      this.tableParam.page = 1;
      this.tableDateRefresh();

    },
    /*表格-置亮搜索关键词 */
    tableLightByKey (val) {
      // 不区分大小写  const Reg = new RegExp(searchData, 'i');
      // 全局替换  const Reg = new RegExp(searchData, 'g');
      if (this.tableParam.key) {
        const Reg = new RegExp(this.tableParam.key, 'ig');
        if (val) {
          // 注意 这里推荐使用正则占位符$& 不使用${searchData}  因为当这里使用正则表达式（i）不区分大小写时，如果你的文本是大写，搜索的关键字是小写，匹配结果会替换掉大写的文本
          // const res = val.replace(Reg, `<span style="background-color: yellow;color:#ffffff">${searchData}</span>`);
          const res = val.replace(Reg, `<span style="background-color: #50bfff;color:#ffffff">$&</span>`);
          return res;
        }
      }
      else {
        return val;
      }
    },
    /*表格-数据重置 */
    tableDateRefresh () {
      getTableData(this.tableParam).then((jsonData) => {
        this.tableData = jsonData.data.data;
        this.tableParam.count = jsonData.data.count;
        this.tableParam.page = jsonData.data.page;
        this.tableParam.limit = jsonData.data.limit;
      })
    },
    showSettingSuccess (data) { //JsonArray,数据回传showSetting成功,更新数据
      this.formData.showSetting = data;
    },


  },
  /*监听Id的变化,如果变化了就重新去取数据 */
  watch: {},
  filters: {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.direcParam = JSON.parse(store.getters.getActiveCus());
    this.formRawData = JSON.parse(JSON.stringify(this.formData)); //深拷贝一份原始数据到formRawData
    this.tableDateRefresh();
  }
}
</script>
