<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="9">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{ this.direcParam.directName }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
      <el-col :span="3">
        <el-select v-model="tableParam.kindId" filterable clearable placeholder="设备类型" style="width:100%">
          <el-option v-for="item in this.allDevKind" :key="'allDevKind'+item.id" :label="item.madeIn+'-'+item.kindName+'-'+item.kindCode" :value="item.id">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-select v-model="tableParam.areaId" filterable clearable placeholder="所属小区" style="width:100%">
          <el-option v-for="item in this.allPublicArea" :key="'allPublicArea'+item.id" :label="item.areaName+'-'+item.areaCode+'-'+item.siteType"
            :value="item.id">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-select v-model="tableParam.priceId" filterable clearable placeholder="收费标准" style="width:100%">
          <el-option v-for="item in this.allPricesInfo" :key="'allPricesInfo'+item.id" :label="item.pricesName" :value="item.id">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="2">
        <el-select v-model="tableParam.sysStatus" filterable clearable placeholder="系统状态" style="width:100%">
          <el-option v-for="(name, id) in allSysStatus" :key="id" :label="name" :value="id"></el-option>

        </el-select>
      </el-col>

      <el-col :span="4">
        <div>
          <el-input placeholder="请输入搜索内容" v-model.trim="tableParam.key" style="width:100%" @change="tableChangeSearchKey">
            <el-button type="primary" slot="append" @click="tableDateRefresh"><i class="fa fa-search" style="color:#409EFF"></i></el-button>
          </el-input>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-button-group>
          <el-button @click="formDataNew" type="primary">新建</el-button>
          <el-button @click="formDataModify" type="primary">修改</el-button>
          <el-button @click="formDataBatchSet" type="success">+批量设定</el-button>
          <el-button @click="formDataDelete" type="danger">删除</el-button>
        </el-button-group>
      </el-col>
    </el-row>

    <el-table ref="infoTableRef" :border="true" height="666" :cell-style="{height:'44px',padding:0,margin:0}" :data="tableData" tooltip-effect="dark"
      style="width: 100%" :highlight-current-row="true" @selection-change="tableSelectionChange" @row-click="tableRowClick">
      <el-table-column header-align="center" type="selection" width="40"></el-table-column>
      <el-table-column header-align="center" type="index" width="40"></el-table-column>
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-descriptions :labelStyle="{'width':'120px','text-align':'right'}" :column="4" style="margin-left:10px;margin-right:10px;" border size="small">
            <el-descriptions-item label="设备类型">{{ props.row.kindName }}-{{ props.row.plugNum }}插</el-descriptions-item>
            <el-descriptions-item label="外壳条码">{{props.row.devCode}}</el-descriptions-item>
            <el-descriptions-item label="经纬度">经:{{props.row.lon}} 纬:{{props.row.lat}} </el-descriptions-item>
            <el-descriptions-item label="区域及代码">{{props.row.province}}-{{props.row.city}}-{{props.row.area}} 代码:{{props.row.areaCode}}</el-descriptions-item>

            <el-descriptions-item :span="2" label="联系地址">{{ props.row.address }}</el-descriptions-item>
            <el-descriptions-item label="最后使用">{{props.row.lastDate}}</el-descriptions-item>
            <el-descriptions-item label="最近上下线">{{props.row.recentUpTime}}&nbsp;{{props.row.recentDownTime}}</el-descriptions-item>

            <el-descriptions-item :span="2" label="设备其它参数">
              <span v-for="(item,index) in props.row.otherInfo" style="margin-right:5px;" size="mini" :key="'other_info'+item.id">{{item.cnName}}:{{item.value}}
              </span>
            </el-descriptions-item>
            <el-descriptions-item :span="2" label="设备网络信息">
              <span v-for="(item,index) in props.row.netInfo" :key="'netInfo_'+item.id" style="margin-right:5px">{{item.cnName}}:{{item.value}}</span>
            </el-descriptions-item>
            <el-descriptions-item :span="4" label="系统设定参数">
              <span style="margin-right:5px;color:#ef0000;cursor: pointer;" title="采用自定义参数" v-if="props.row.setInfo.useCustomize">自定义</span>
              <span style="margin-right:5px;color:#409EFF;cursor: pointer;" title="采用小区定义参数" v-else>小区</span>
              <el-tag type="info" size="small" style="margin-right:5px;">空载断电时长:{{props.row.setInfo.elecNullSecond}}秒</el-tag>
              <el-tag type="info" size="small" style="margin-right:5px;">充满自停功率:{{props.row.setInfo.elecFullPower}}瓦</el-tag>
              <el-tag type="info" size="small" style="margin-right:5px;">允许最大功率:{{props.row.setInfo.elecMaxPower}}瓦</el-tag>
              <el-tag type="info" size="small" style="margin-right:5px;">充满等待时间:{{props.row.setInfo.elecWaitSecond}}秒</el-tag>
              <el-tag type="info" size="small" style="margin-right:5px;">电价标准:{{props.row.setInfo.elePrice|yuanFilter}}元/度</el-tag>
              <el-tag type="info" size="small" style="margin-right:5px;">服务费:{{props.row.setInfo.servicePrice|yuanFilter}}元/度</el-tag>
            </el-descriptions-item>
            <el-descriptions-item :span="4" label="设备参数信息">
              <el-tag type="info" v-for="(item,index) in props.row.paramInfo" :key="'paramInfo_'+item.id" size="small"
                style="margin-right:5px;margin-bottom:5px;">{{item.title}}:{{item.cnName}}</el-tag>
            </el-descriptions-item>
          </el-descriptions>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="编号/设备号" prop="devNo" width="160">
      </el-table-column>
      <el-table-column header-align="center" label="小区名称" prop="areaName" show-overflow-tooltip>
      </el-table-column>
      <el-table-column header-align="center" label="收费标准" prop="priceName" width="320">
        <template slot-scope="scope">
          <span style="margin-right:5px;color:#ef0000;cursor: pointer;" title="采用自定义参数" v-if="scope.row.setInfo.useCustomize">自</span>
          <span style="margin-right:5px;color:#409EFF;cursor: pointer;" title="采用小区定义参数" v-else>区</span>
          {{ scope.row.priceName}}
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="信号" prop="signValue" width="70" align="center">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" :content="getSignaTextByDepth(scope.row.signValue)" placement="top-start">
            <i class="fa fa-signal" :style="'color:'+getSignalColorByDepth(scope.row.signValue)"></i>
          </el-tooltip>&nbsp;
          <el-tooltip class="item" effect="dark" content="在线" placement="top-start" v-if="scope.row.isOnline">
            <i class="fa fa-link" style="color:#67C23A"></i>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="离线" placement="top-start" v-else>
            <i class="fa fa-unlink" style="color:#ef0000"></i>
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column header-align="center" label="创建时间" prop="createTime" width="140"></el-table-column>
      <el-table-column header-align="center" label="插座" width="470">
        <template slot-scope="scope">
          <el-popover placement="top" :width="30" trigger="click" v-for="(item,index) in scope.row.plugStatus" :key="'plugStatus'+scope.row.id+'_'+index">
            <div style="text-align: center;">
              <div>当前订单号:{{ item.bindOrder>0? item.bindOrder:'未绑定'}}</div>
              <el-button size="mini" style="padding:5px;" @click="sendStartData(scope.row.kindId,scope.row.devNo,item.place)">
                <i class="fa fa-play" style="color:#409EFF" :key="'powerStart'+item">&nbsp;</i>启动
              </el-button>
              <el-button size="mini" style="padding:5px;" @click="sendStopData(scope.row.kindId,scope.row.devNo,item.place,item.bindOrder)">
                <i class="fa fa-power-off" style="color:#ef0000" :key="'powerStop'+item">&nbsp;</i>停止
              </el-button>
            </div>
            <el-button slot="reference" size="mini" style="padding:5px;" :key="'plugStatus'+scope.row.id+'_button_'+index">
              <i class="fa fa-plug" v-if="item.isOpen" style="color:#409EFF" :key="'power'+item.place"> {{ item.place }}</i>
              <i class="fa fa-life-buoy" v-else style="color:#909399" :key="'power'+item.place"> {{ item.place }}</i>
            </el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="状态" prop="sysStatusName" width="60" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.sysStatus<1" style="color:#E6A23C">{{ scope.row.sysStatusName }}</span>
          <span v-else-if="scope.row.sysStatus>1" style="color:#ef0000">{{ scope.row.sysStatusName }}</span>
          <span v-else="scope.row.sysStatus==4" style="color:#409EFF">{{ scope.row.sysStatusName }}</span>
        </template>
      </el-table-column>

      <el-table-column label="信息" width="140">
        <template slot-scope="scope">
          <el-popover placement="bottom" width="200" trigger="click">
            <img :src="devQrUrl" style="width:200px;" />
            <i class="fa fa-qrcode" style="color:#409EFF" title="设备二维码" slot="reference" @click="createQrCode(scope.row.devNo)"></i>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <el-button-group>
            <el-button title="恢复出厂设置" size="mini" type="primary" style="padding:5px" @click="sendInitDefault(scope.row.devNo,scope.row.kindId)">
              <i class="el-icon-setting"></i>
            </el-button>
            <el-button title="参数查询" size="mini" type="primary" style="padding:5px" @click="sendSearchParam(scope.row.devNo,scope.row.kindId)">
              <i class="fa fa-search"></i>
            </el-button>
            <el-button title="重启" size="mini" type="primary" style="padding:5px" @click="sendRestartData(scope.row.devNo,scope.row.kindId)">
              <i class="fa fa-refresh"></i>
            </el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="tableSizeChange" @current-change="tableCurrentChange" :current-page="tableParam.page"
      :page-sizes="[30,40, 50, 60,70,80,90,100]" :page-size="tableParam.limit" layout="total, sizes, prev, pager, next, jumper" :total="tableParam.count">
    </el-pagination>

    <!--编辑的表单-->

    <el-dialog v-dialogDrag :title="formDialogParam.title" :visible.sync="formDialogParam.visible" :width="formDialogParam.width" :top="formDialogParam.top"
      :close-on-click-modal='false'>
      <el-form ref="infoFormRef" :model="formData" :rules="formDialogRules" label-width="90px" size="small"><!-- `prop` 为绑定的规则 -->
        <el-row :gutter="20" style="padding:0px;">
          <el-col :span="10">
            <el-form-item label="设备类型" prop="kindId">
              <el-select v-model="formData.kindId" filterable placeholder="设备类型" @change="changeKindId" style="width:100%" ref="selectKindId" id="kindId">
                <el-option v-for="item in this.allDevKind" :key="'allDevKind'+item.id" :label="item.madeIn+'-'+item.kindName+'-'+item.kindCode"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="设备编号" prop="devNo">
              <el-input id="devNo" v-model="formData.devNo" placeholder="请输入编号/设备号" maxlength="50" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="外壳条码" prop="devCode">
              <el-input id="devCode" v-model="formData.devCode" placeholder="请输入外壳条码" maxlength="50" show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20" style="padding:0px;">
          <el-col :span="10">
            <el-form-item label="所属小区" prop="areaId">
              <el-select v-model="formData.areaId" filterable placeholder="选择所属小区" @change="changePublicArea" style="width:100%" ref="selectPublicArea"
                id="areaId">
                <el-option v-for="item in this.allPublicArea" :key="'allPublicArea'+item.id" :label="item.areaName+'-'+item.areaCode+'-'+item.siteType"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item label="收费标准" prop="priceId">
              <el-select id="priceId" v-model="formData.priceId" filterable placeholder="选择收费标准" @change="changePrice" style="width:100%" ref="selectPrice">
                <el-option v-for="item in this.allPricesInfo" :key="'allPricesInfo'+item.id" :label="item.pricesName" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="系统状态" prop="sysStatus">
              <el-select id="sysStatus" v-model="formData.sysStatus" filterable placeholder="未知" @change="changeSysStatus" style="width:100%"
                ref="selectSysStatus">
                <el-option v-for="(name, id) in allSysStatus" :key="id" :label="name" :value="id-0"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="充电桩参数" style="margin-bottom:0px;">
          <el-checkbox v-model="formData.setInfo.useCustomize" @change="changeSetInfo">使用自定义参数设定</el-checkbox><span
            style="color:#409EFF">&nbsp;&nbsp;&nbsp;&nbsp;*使用自定义参数后,小区的设定参数和收费标准修改将不对此设备生效. </span>
        </el-form-item>
        <el-card v-if="formData.setInfo.useCustomize">
          <el-row style="padding:0px;" :gutter="10">
            <el-col :span="8">
              <el-form-item label="空载断电时长" prop="setInfo.elecNullSecond" label-width="110" size="mini">
                <el-input id="setInfo.elecNullSecond" v-model="formData.setInfo.elecNullSecond" placeholder="请输入空载断电时长" type="number" step="0" :min="30"
                  :max="240" style="width:60%">
                  <template slot="append">秒</template></el-input>
              </el-form-item>
              <el-form-item label="充满自停功率" prop="setInfo.elecFullPower" label-width="110" size="mini">
                <el-input id="setInfo.elecFullPower" v-model="formData.setInfo.elecFullPower" placeholder="请输入设备充满自停功率" type="number" :min="10" :max="1000"
                  style="width:60%">
                  <template slot="append">瓦</template></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="允许最大功率" prop="setInfo.elecMaxPower" label-width="110" size="mini">
                <el-input id="setInfo.elecMaxPower" v-model="formData.setInfo.elecMaxPower" placeholder="请输入允许最大功率" type="number" :min="10" :max="10000"
                  style="width:60%">
                  <template slot="append">瓦</template></el-input>
              </el-form-item>
              <el-form-item label="充满等待时间" prop="setInfo.elecWaitSecond" label-width="110" size="mini">
                <el-input id="setInfo.elecWaitSecond" v-model="formData.setInfo.elecWaitSecond" placeholder="请输入设备充满等待时间" type="number" :min="10" :max="10000"
                  style="width:60%">
                  <template slot="append">秒</template></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="电价标准" prop="setInfo.elePrice">
                <el-input id="setInfo.elePrice" v-model="formData.setInfo.elePrice" type="number" :min="10" :max="1000" placeholder="请输入电价">
                  <template slot="append">{{formData.setInfo.elePrice|yuanFilter}}元/度</template>
                </el-input>
              </el-form-item>
              <el-form-item label="服务费" prop="setInfo.servicePrice">
                <el-input id="setInfo.servicePrice" v-model="formData.setInfo.servicePrice" type="number" :min="10" :max="1000" placeholder="请输入服务费">
                  <template slot="append">{{formData.setInfo.servicePrice|yuanFilter}}元/度</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
        <h4>设备详情</h4>
        <el-descriptions :labelStyle="{'width':'120px','text-align':'right'}" :column="4" style="margin-left:10px;margin-right:10px;" border size="small">
          <el-descriptions-item :span="4" label="小区信息">{{formData.province}}-{{formData.city}}-{{formData.area}}-{{formData.address}}({{ formData.areaCode }})
          </el-descriptions-item>
          <el-descriptions-item :span="4" label="收费标准" v-if="formData.priceId">{{formData.priceName}}
            <span v-if="formData.priceShowInfo.showKind==0">按金额:
              <el-tag slot="reference" v-for="(item,index) in formData.priceShowInfo.showSetting" size="small" style="margin-left: 3px;"
                :key="'moneyType_'+formData.priceShowInfo.id+'_'+index">
                <i class="fa fa-bolt" style="color:#409EFF" :key="'showSetting'+item.id">
                  {{ item.value|yuanFilter }}{{ formData.priceShowInfo.showKindTitle }}</i>
              </el-tag>
            </span>
            <span v-else-if="formData.priceShowInfo.showKind==1">按电量:
              <el-tag slot="reference" v-for="(item,index) in formData.priceShowInfo.showSetting" size="small" style="margin-left: 3px;"
                :key="'powerType_'+formData.priceShowInfo.id+'_'+index">
                <i class="fa fa-bolt" style="color:#409EFF" :key="'showSetting'+item.id"> {{ item.value}}{{ formData.priceShowInfo.showKindTitle }}</i>
              </el-tag>
            </span>
            <span v-if="formData.priceShowInfo.enoughCan">

              <el-tooltip placement="top">
                <div slot="content" style="line-height:20px;">
                  收款方式:
                  <span v-if="formData.enoughType==0">预收费,结算后,余额按[退款方式]退回</span>
                  <span v-else-if="formData.enoughType==1">固定金额充满</span><br />
                  收费金额:{{formData.priceShowInfo.enoughFunds|yuanFilter}} 元<br />
                  时长限制:{{formData.priceShowInfo.enoughMaxTime}} 分钟 约{{(formData.priceShowInfo.enoughMaxTime/60).toFixed(2)}}小时 <br />
                </div>
                <el-tag size="small" type="success" style="margin-left: 3px;">
                  <i class="fa fa-rocket">充满自停</i>
                </el-tag>
              </el-tooltip>
            </span>
          </el-descriptions-item>
          <el-descriptions-item label="经度">{{formData.lon}}</el-descriptions-item>
          <el-descriptions-item label="纬度">{{formData.lat}}</el-descriptions-item>
          <el-descriptions-item label="创建时间">{{formData.createTime}}</el-descriptions-item>
          <el-descriptions-item label="最后使用">{{formData.lastDate}}</el-descriptions-item>
          <el-descriptions-item label="最近上下线" :span="2">{{formData.recentUpTime}}->{{formData.recentDownTime}}</el-descriptions-item>
          <el-descriptions-item label="信号强度">{{formData.signValue}}</el-descriptions-item>
          <el-descriptions-item label="系统状态"><span v-if="formData.isOnline">在线</span><span
              v-else>离线</span>{{formData.sysStatus}}&nbsp;{{formData.sysStatusTitle}}</el-descriptions-item>
          <el-descriptions-item :span="4" label="设备其它参数">
            <span v-for="(item,index) in formData.otherInfo" style="margin-right:5px;" :key="'other_info'+item.id">{{item.cnName}}:{{item.value}}
            </span>
          </el-descriptions-item>
          <el-descriptions-item :span="4" label="设备网络信息">
            <span v-for="(item,index) in formData.netInfo" :key="'netInfo_'+item.id" style="margin-right:5px">{{item.cnName}}:{{item.value}}</span>
          </el-descriptions-item>
          <el-descriptions-item :span="4" label="系统设定参数">
            <el-tag type="info" size="small" style="margin-right:5px;">空载断电时长:{{formData.setInfo.elecNullSecond}}秒</el-tag>
            <el-tag type="info" size="small" style="margin-right:5px;">充满自停功率:{{formData.setInfo.elecFullPower}}瓦</el-tag>
            <el-tag type="info" size="small" style="margin-right:5px;">允许最大功率:{{formData.setInfo.elecNullSecond}}瓦</el-tag>
            <el-tag type="info" size="small" style="margin-right:5px;">充满等待时间:{{formData.setInfo.elecNullSecond}}秒</el-tag>
            <el-tag type="info" size="small" style="margin-right:5px;">电价标准:{{formData.setInfo.elePrice|yuanFilter}}元/度</el-tag>
            <el-tag type="info" size="small" style="margin-right:5px;">服务费:{{formData.setInfo.servicePrice|yuanFilter}}元/度</el-tag>
          </el-descriptions-item>
          <el-descriptions-item :span="4" label="设备参数信息">
            <el-tag type="info" v-for="(item,index) in formData.paramInfo" :key="'paramInfo_'+item.id" size="small"
              style="margin-right:5px;margin-top:3px;">{{item.title}}:{{item.cnName}}</el-tag>
          </el-descriptions-item>

        </el-descriptions>
      </el-form>
      <span slot="footer">
        <el-button @click="formDialogParam.visible = false">取 消</el-button>
        <el-button type="primary" @click="formDataSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog v-dialogDrag :title="formChooseDialogParam.title" :visible.sync="formChooseDialogParam.visible" width="50%" :close-on-click-modal='false'>
      <el-table :data="tableMultipleSelection" style="width: 100%;" height="300">
        <el-table-column header-align="center" type="index" width="40"></el-table-column>
        <el-table-column header-align="center" label="编号/设备号" prop="devNo" width="120">
        </el-table-column>
        <el-table-column header-align="center" label="小区名称" prop="areaName" show-overflow-tooltip>
        </el-table-column>
        <el-table-column header-align="center" label="收费标准" prop="priceName" width="270">
        </el-table-column>
        <el-table-column header-align="center" label="状态" prop="sysStatusName" width="60" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.sysStatus<1" style="color:#E6A23C">{{ scope.row.sysStatusName }}</span>
            <span v-else-if="scope.row.sysStatus>1" style="color:#ef0000">{{ scope.row.sysStatusName }}</span>
            <span v-else="scope.row.sysStatus==4" style="color:#409EFF">{{ scope.row.sysStatusName }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div style="width:100%;height:20px;"></div>
      <el-row :gutter="20" v-if="formChooseDialogParam.action2">
        <el-col :span="3" style="text-align:right;line-height:24px;">批量设定</el-col>
        <el-col :span="9">
          <el-select v-model="formChooseDialogParam.areaId" filterable placeholder="选择所属小区" style="width:100%" size="small">
            <el-option v-for="item in this.allPublicArea" :key="'allPublicArea'+item.id" :label="item.areaName+'-'+item.areaCode+'-'+item.siteType"
              :value="item.id">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="7">
          <el-select v-model="formChooseDialogParam.priceId" filterable placeholder="选择收费标准" style="width:100%" size="small">
            <el-option v-for="item in this.allPricesInfo" :key="'allPricesInfo'+item.id" :label="item.pricesName" :value="item.id">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-select v-model="formChooseDialogParam.sysStatus" filterable placeholder="选择系统状态" style="width:100%" size="small">
            <el-option v-for="(name, id) in allSysStatus" :key="id" :label="name" :value="id">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <span slot="footer">
        <el-button @click="formChooseDialogParam.visible = false">取 消</el-button>
        <el-button v-if="formChooseDialogParam.action1" type="danger" @click="formChooseSubmit(false)">{{formChooseDialogParam.action1View}}</el-button>
        <el-button v-if="formChooseDialogParam.action2" type="primary" @click="formChooseSubmit(true)">{{formChooseDialogParam.action2View}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<style lang="less" scoped>
@import "@/assets/css/TableCus.css";
</style>
<script>
import TableOtherInfo from '@/components/dev/info/TableOtherInfo.vue';
import TagOtherInfo from '@/components/dev/info/TagOtherInfo.vue';

import store from '@/store';
import copy from 'fast-copy';
import { getDevQrCode } from '@/api/utils/QrCode';
import { getAllDevKind, getSysStatus, getAllPricesInfo, getAllPublicArea } from '@/api/utils/BizUtils';
import { getTableData, doNew, doModify, doDelete, doBatchSet, sendStart, sendStop, sendRestart, initDefault, searchParam } from '@/api/dev/Info'; //数据源,如果没有上传文件的部分,请删除postFile, postImg,不删除也不影响
import { required, phone, len, email } from "@/utils/formRules" //校验规则,如有特殊要求请自己去修改填写,请删除不使用的,但不删除也不影响
export default {
  components: { TableOtherInfo, TagOtherInfo },
  data () {
    return {
      allDevKind: [],
      allSysStatus: {},
      allPricesInfo: [],
      allPublicArea: [],
      devQrUrl: '',
      /*导航栏状态 */
      direcParam: {
        'directName': '名称名称名称名称名称名称名称名称名称Info'
      },
      /*表格参数 */
      tableParam: {
        'page': 1,
        'limit': 40,
        'count': 0,
        'kindId': '',
        'areaId': '',
        'priceId': '',
        'sysStatus': '',
        'key': '',
        'tableKey': 0,
        'searchType': 'normal'
      },//表格的传参
      /*表格数据 */
      tableData: [],
      /*表格选中的数据 */
      tableMultipleSelection: [], //多选的数据
      /*表单的参数 */
      formDialogParam: //form弹出窗口的显示状态
      {
        visible: false,
        title: '编辑',
        formDataAction: '',
        width: '60%',
        top: '5vh',
      },
      formChooseDialogParam: //form发布弹出窗口的显示状态
      {
        formChooseAction: '',
        title: '',
        visible: false,
        action1: false,
        action1View: '',
        action2: false,
        action2View: '',
        areaId: '',
        priceId: '',
        sysStatus: '',
      },
      /*原始数据 */
      formRawData: {}, //由于不能直接清空所有的数据回到初使状态(表格参数据库和搜索数据不能清空),所以建立,在mount的时候作一个深拷贝,将formData的数据初使化的原始数据复制一份过来
      /*初使化数据 */
      formData: {
        kindId: 0,
        kindName: '',
        devNo: '',
        devCode: '',
        areaId: '',
        areaName: '',
        areaCode: '',
        address: '',
        province: '',
        city: '',
        area: '',
        priceId: '',
        priceName: '',
        priceShowInfo: {},
        lon: '',
        lat: '',
        signValue: '',
        createTime: '',
        lastDate: new Date(),
        recentUpTime: '',
        recentDownTime: '',

        sysStatus: '',
        sysStatusName: '',
        isOnline: false,

        otherInfo: [],
        plugNum: 0,
        plugStatus: [],
        netInfo: [],
        paramInfo: [],
        setInfo: {
          useCustomize: false,
          elecNullSecond: 0,
          elecFullPower: 0,
          elecMaxPower: 0,
          elecWaitSecond: 0,
          elePrice: 0,
          servicePrice: 0
        },
        owner: '',
        ownerName: '',


      },
      formDialogRules:
      {
        kindId: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        kindName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        devNo: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        areaId: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        priceId: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        sysStatus: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        province: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        city: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        area: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        address: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        lon: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        lat: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        isOnline: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        otherInfo: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        setInfo: {
          elecNullSecond: [required],
          elecFullPower: [required],
          elecMaxPower: [required],
          elecWaitSecond: [required],
          elePrice: [required],
          servicePrice: [required]
        },

        // title: [required,len(0,255)],多规则绑定
        // mail: [required,len(0,255), email],多规则绑定
        // authorWriter: [required],
      },
    }
  },
  methods: {
    changeSetInfo () {
      if (!this.formData.setInfo.useCustomize)//如果不是使用自定义价格
      {
        const selectedPricesOption = this.allPricesInfo.find(item => item.id === this.formData.priceId);
        this.$set(this.formData.setInfo, 'elePrice', selectedPricesOption.elePrice);
        this.$set(this.formData.setInfo, 'servicePrice', selectedPricesOption.servicePrice);
        const selectedAreaOption = this.allPublicArea.find(item => item.id === this.formData.areaId);
        this.$set(this.formData.setInfo, 'elecNullSecond', selectedAreaOption.setInfo.elecNullSecond);
        this.$set(this.formData.setInfo, 'elecFullPower', selectedAreaOption.setInfo.elecFullPower);
        this.$set(this.formData.setInfo, 'elecMaxPower', selectedAreaOption.setInfo.elecMaxPower);
        this.$set(this.formData.setInfo, 'elecWaitSecond', selectedAreaOption.setInfo.elecWaitSecond);
      }
    },
    /*变更设备类型 */
    changeKindId () {
      this.$nextTick(() => {
        this.formData.kindName = this.$refs.selectKindId.selectedLabel;//主要原因是Dom更新的原因,如果直接取值,取到的是当前值
      })
    },
    /*变更小区 */
    changePublicArea (areaId) {
      const selectedOption = this.allPublicArea.find(item => item.id === areaId);
      this.formData.areaCode = selectedOption.areaCode;
      this.formData.areaName = selectedOption.areaName;
      this.formData.province = selectedOption.areaLimt.provinceName;
      this.formData.city = selectedOption.areaLimt.cityName;
      this.formData.area = selectedOption.areaLimt.areaName;
      this.formData.address = selectedOption.areaAddress;
      this.changeSetInfo(); //变更设备的设定

    },
    /*变更价格 */
    changePrice (priceId) {
      const selectedOption = this.allPricesInfo.find(item => item.id === priceId);
      this.formData.priceName = selectedOption.pricesName;
      this.formData.priceShowInfo = selectedOption;
      this.changeSetInfo(); //变更设备的设定
    },
    /*变更设备状态 */
    changeSysStatus () {
      this.$nextTick(() => {
        this.formData.sysStatusName = this.$refs.selectSysStatus.selectedLabel;//主要原因是Dom更新的原因,如果直接取值,取到的是当前值
      })
    },
    /**生成设备的QR码 **/
    createQrCode (qrStr) {
      getDevQrCode({ qrStr: qrStr }).then((response) => {
        if (response.data.success) {
          this.devQrUrl = response.data.data;
        }
        else {
          this.notifyWarning(response.data.msg + ":" + response.data.data);
        }
      })
    },
    /**信号强度的函数 */
    getSignalColorByDepth (signalStrength) {
      let value = signalStrength / 8;
      if (value > 3) {
        return '#409EFF';
      }
      else if (value > 2) {
        return '#67C23A';

      }
      else if (value > 1) {
        return '#E6A23C';

      }
      else if (value > 0) {
        return '#F56C6C';
      }
      else {
        return '#909399';
      }
    },
    getSignaTextByDepth (signalStrength) {
      let value = signalStrength / 5;
      if (value > 3) {
        return '信号:强-' + signalStrength;
      }
      else if (value > 2) {
        return '信号:正常-' + signalStrength;

      }
      else if (value > 1) {
        return '信号:微弱-' + signalStrength;

      }
      else if (value > 0) {
        return '信号:弱-' + signalStrength;
      }
      else {
        return '信号:极弱-' + signalStrength;
      }
    },

    sendStartData (kindId, devNo, index) {
      const data = new FormData()
      data.append('kindId', kindId);
      data.append('devNo', devNo);
      data.append('index', index);
      sendStart(data).then(response => {
        if (response.data.success) {
          this.notifySuccess(response.data.msg);
          this.tableDateRefresh();
        }
        else {
          this.notifyWarning(response.data.msg);
          this.tableDateRefresh();
        }
      })
    },
    sendStopData (kindId, devNo, index, orderId) {
      const data = new FormData()
      data.append('kindId', kindId);
      data.append('devNo', devNo);
      data.append('index', index);
      data.append('orderId', orderId);
      sendStop(data).then(response => {
        if (response.data.success) {
          this.notifySuccess(response.data.msg);
          this.tableDateRefresh();
        }
        else {
          this.notifyWarning(response.data.msg);
          this.tableDateRefresh();
        }
      })
    },
    /**下发查询参数命令**/
    sendSearchParam (devNo, kindId) {
      const data = new FormData()
      data.append('devNo', devNo);
      data.append('kindId', kindId);
      searchParam(data).then(response => {
        if (response.data.success) {
          this.notifySuccess(response.data.msg);
          this.tableDateRefresh();
        }
        else {
          this.notifyWarning(response.data.msg);
          this.tableDateRefresh();
        }
      })
    },
    /**恢复出厂设置**/
    sendInitDefault (devNo, kindId) {
      const data = new FormData()
      data.append('devNo', devNo);
      data.append('kindId', kindId);
      initDefault(data).then(response => {
        if (response.data.success) {
          this.notifySuccess(response.data.msg);
          this.tableDateRefresh();
        }
        else {
          this.notifyWarning(response.data.msg);
          this.tableDateRefresh();
        }
      })
    },

    sendRestartData (devNo, kindId) {
      const data = new FormData()
      data.append('devNo', devNo);
      data.append('kindId', kindId);
      sendRestart(data).then(response => {
        if (response.data.success) {
          this.notifySuccess(response.data.msg);
          this.tableDateRefresh();
        }
        else {
          this.notifyWarning(response.data.msg);
          this.tableDateRefresh();
        }
      })
    },
    /*-------------------------------------------------表单(form)事件 -----------------------------------------------*/
    /*表单(form)-新建 */
    formDataNew () {
      this.formData = JSON.parse(JSON.stringify(this.formRawData)); //深拷贝一份原始数据到formData,就相当于重置表单了
      this.formDialogParam.title = '新建';
      this.formDialogParam.formDataAction = 'new';
      this.formDialogParam.visible = true;
    },
    /*表单(form)-修改 */
    formDataModify () {
      if (this.tableMultipleSelection.length != 1) {
        this.notifyInfo("您只能选择一条记录,才能修改");
      }
      else {
        this.formData = copy(this.tableMultipleSelection[0]); //深COPY主要是为了不想改了,就渲染到table上造成不必要的麻烦;
        console.log(this.formData);
        this.formDialogParam.formDataAction = 'modify';
        this.formDialogParam.title = '修改';
        this.formDialogParam.visible = true;
      }

    },
    /*表单(form)-删除 */
    formDataDelete () {
      if (this.tableMultipleSelection.length < 1) {
        this.notifyInfo("您必须最少选择一条记录,才能删除");
        return;
      }
      else {
        this.formChooseDialogParam.title = "删除设备的所有记录,包括用户订单,非系统管理员请勿操作"
        this.formChooseDialogParam.formChooseAction = 'delete';
        this.formChooseDialogParam.action1 = true;
        this.formChooseDialogParam.action1View = '删 除';
        this.formChooseDialogParam.action2 = false;
        this.formChooseDialogParam.action2View = '';
        this.formChooseDialogParam.visible = true;
      }
    },

    formDataBatchSet () {
      if (this.tableMultipleSelection.length < 1) {
        this.notifyInfo("您必须最少选择一条记录,才能批量编辑");
      }
      else {
        this.formChooseDialogParam.title = "批量编辑"
        this.formChooseDialogParam.formChooseAction = 'batchSet';
        this.formChooseDialogParam.action1 = false;
        this.formChooseDialogParam.action1View = '取 消';
        this.formChooseDialogParam.action2 = true;
        this.formChooseDialogParam.action2View = '确 定';
        this.formChooseDialogParam.visible = true;
        this.formChooseDialogParam.transSelectVisible = false;
      }
    },
    formDataValidate () {
      let result = true;
      this.$refs.infoFormRef.validate((valid) => {
        result = valid;
      });
      return result;
    },
    /*表单(form)-提交 */
    formDataSubmit () {
      if (!this.formDataValidate()) return;
      this.formDialogParam.visible = false;
      //如有特殊的Json要求,请深Copy一份,再进行修改例如
      const postData = JSON.parse(JSON.stringify(this.formData));
      postData.otherInfo = JSON.stringify(this.formData.otherInfo);//由于MysqlJson的原因
      postData.netInfo = JSON.stringify(this.formData.netInfo);//由于MysqlJson的原因
      postData.paramInfo = JSON.stringify(this.formData.paramInfo);//由于MysqlJson的原因
      postData.plugStatus = JSON.stringify(this.formData.plugStatus);//由于MysqlJson的原因
      postData.setInfo = JSON.stringify(this.formData.setInfo);//由于MysqlJson的原因
      postData.priceShowInfo = JSON.stringify(this.formData.priceShowInfo);//由于MysqlJson的原因
      if (this.formDialogParam.formDataAction == 'new') {
        doNew(postData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })
      }
      else if (this.formDialogParam.formDataAction == 'modify') {
        doModify(postData).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
    },
    formChooseSubmit () {
      console.log(this.formChooseDialogParam.formChooseAction)
      this.formChooseDialogParam.visible = false;
      if (this.formChooseDialogParam.formChooseAction == 'delete') {
        const selectNum = this.tableMultipleSelection.length;
        let canId = [];
        for (let i = 0; i < selectNum; i++) {
          if (!this.tableMultipleSelection[i].isPublic) {
            canId.push(this.tableMultipleSelection[i].id);
          }
        }
        const data = new FormData()
        data.append('id', canId);
        doDelete(data).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
      else if (this.formChooseDialogParam.formChooseAction == 'batchSet') {

        const selectNum = this.tableMultipleSelection.length;
        let canId = [];
        for (let i = 0; i < selectNum; i++) {
          canId.push(this.tableMultipleSelection[i].id);
        }
        if (!(this.formChooseDialogParam.areaId && this.formChooseDialogParam.priceId && this.formChooseDialogParam.sysStatus)) {
          this.notifyWarning("请选择小区,价格策略和系统状态");
          this.formChooseDialogParam.visible = true;
          return;
        }
        const data = new FormData()
        data.append('id', canId);
        data.append('areaId', this.formChooseDialogParam.areaId);
        data.append('priceId', this.formChooseDialogParam.priceId);
        data.append('sysStatus', this.formChooseDialogParam.sysStatus);
        doBatchSet(data).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
    },
    /*-------------------------------------------------表格事件 -----------------------------------------------*/
    //表格-多选事件,当checkBox被选中,改变已选的值
    tableSelectionChange (val) {
      this.tableMultipleSelection = val;
    }
    ,
    /*表格-行选,点击事件 */
    tableRowClick (row) {
      //this.formData = row;
      this.formData = JSON.parse(JSON.stringify(row));//主要是为了不想改了就生效到table上
      this.$refs.infoTableRef.clearSelection(); //清除选中
      this.$refs.infoTableRef.toggleRowSelection(row); //设定选中
    },
    /*表格-改变每页显示多少条,直接重置到第一页*/
    tableSizeChange (val) {
      if (val != this.tableParam.limit) {
        this.tableParam.limit = val
        this.tableParam.page = 1;
        this.tableDateRefresh();
      }
    },
    /*表格-改变第几页*/
    tableCurrentChange (val) {
      if (val != this.tableParam.page) {
        this.tableParam.page = val;
        this.tableDateRefresh();
      }

    },
    /*表格-搜索关键词,由于改变才会重搜,所以不用在意 */
    tableChangeSearchKey (searchKey) {
      this.tableParam.key = searchKey;
      this.tableParam.page = 1;
      this.tableDateRefresh();

    },
    /*表格-置亮搜索关键词 */
    tableLightByKey (val) {
      // 不区分大小写  const Reg = new RegExp(searchData, 'i');
      // 全局替换  const Reg = new RegExp(searchData, 'g');
      if (this.tableParam.key) {
        const Reg = new RegExp(this.tableParam.key, 'ig');
        if (val) {
          // 注意 这里推荐使用正则占位符$& 不使用${searchData}  因为当这里使用正则表达式（i）不区分大小写时，如果你的文本是大写，搜索的关键字是小写，匹配结果会替换掉大写的文本
          // const res = val.replace(Reg, `<span style="background-color: yellow;color:#ffffff">${searchData}</span>`);
          const res = val.replace(Reg, `<span style="background-color: #50bfff;color:#ffffff">$&</span>`);
          return res;
        }
      }
      else {
        return val;
      }
    },
    /*表格-数据重置 */
    tableDateRefresh () {
      getTableData(this.tableParam).then((jsonData) => {
        this.tableData = jsonData.data.data;
        this.tableParam.count = jsonData.data.count;
        this.tableParam.page = jsonData.data.page;
        this.tableParam.limit = jsonData.data.limit;
      })
    },
    otherInfoSuccess (data) { //JsonArray,数据回传otherInfo成功,更新数据
      this.formData.otherInfo = data;
    },


  },
  /*监听Id的变化,如果变化了就重新去取数据 */
  watch: {},
  filters: {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.direcParam = JSON.parse(store.getters.getActiveCus());
    this.formRawData = JSON.parse(JSON.stringify(this.formData)); //深拷贝一份原始数据到formRawData
    this.tableDateRefresh();
    getAllDevKind().then(response => {
      if (response.data.success) {
        this.allDevKind = response.data.data;
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    });
    getSysStatus().then(response => {
      if (response.data.success) {
        this.allSysStatus = response.data.data;
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    });
    getAllPricesInfo().then(response => {
      if (response.data.success) {
        this.allPricesInfo = response.data.data;
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    });
    getAllPublicArea().then(response => {
      if (response.data.success) {
        this.allPublicArea = response.data.data;
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    });
  }
}
</script>
